import { TABS, FILTER_ACTIONS as types } from '../model';
import { getTabFromEvent } from '../reducers/filters';
import { browserHistory } from 'react-router';
import * as api from '../lib/api';
import * as commonActions from './common';
import * as paginationActions from './pagination';
import * as tableActions from './table';

const reloadWithFilters = () => (dispatch) => {
  dispatch(paginationActions.resetPage());
  dispatch(paginationActions.fetchRowCount());
  return dispatch(tableActions.fetchData());
};

const changeTab = (tab, forceAPICall = false) => (dispatch, getState) => {
  const state = getState();

  if (!forceAPICall && state.filters.activeTab === tab) {
    return;
  }

  dispatch({ type: types.CHANGE_TAB, tab });

  if (tab === TABS.ALL) {
    browserHistory.push(window.location.pathname);
  } else {
    browserHistory.push(`${window.location.pathname}?tab=${tab}`);
  }

  return dispatch(reloadWithFilters());
};

const changeAssetType = (assetType) => (dispatch) => {
  dispatch({ type: types.CHANGE_ASSET_TYPE, assetType });
  return dispatch(reloadWithFilters());
};

const changeEventFilter = (event) => (dispatch) => {
  dispatch({ type: types.CHANGE_EVENT, event });

  const tab = getTabFromEvent(event);

  dispatch(changeTab(tab, true));
};

const changeDateRange = (date) => (dispatch) => {
  dispatch({ type: types.CHANGE_DATE_RANGE, date });
  return dispatch(reloadWithFilters());
};

const changeAffectedItemSearch = (value)  => (dispatch) => {
  dispatch({ type: types.CHANGE_AFFECTED_ITEM_SEARCH, value });
  return dispatch(reloadWithFilters());
};

const changeInitiatedBySearch = (value) => (dispatch) => {
  dispatch({ type: types.CHANGE_INITIATED_BY_SEARCH, value });
  return dispatch(reloadWithFilters());
};

const clearAllFilters = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ALL_FILTERS });
  return dispatch(reloadWithFilters());
};

const storeFilterQueryMapping = (data) => ({
  type: types.STORE_FILTER_QUERY_MAPPING,
  data
});

const fetchFilterQueryMapping = () => (dispatch, getState) => {
  const state = getState();

  dispatch(commonActions.apiCallInProgress());

  const apiResponse = api.
    fetchFilterQueryMapping();

  const resolvedApiResponse = apiResponse.
    then((mapping) => {
      return dispatch(storeFilterQueryMapping(mapping));
    }).
    then(() => {
      dispatch(commonActions.apiCallSuccess());
      dispatch(paginationActions.fetchRowCount());
      dispatch(tableActions.fetchData());
    }).
    catch((error) => {
      return dispatch(commonActions.apiException(error));
    });


  return resolvedApiResponse;
};

export {
  changeTab,
  changeAssetType,
  changeEventFilter,
  changeDateRange,
  changeAffectedItemSearch,
  changeInitiatedBySearch,
  clearAllFilters,
  fetchFilterQueryMapping
};
