import _ from 'lodash';
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import LocalizedText from 'common/i18n/components/LocalizedText';
import { DATA_UPDATE_METHODS } from '../../constants';
import * as actions from '../../actions';

class ActionsCell extends PureComponent {

  hasDetails(activity) {
    const activityType = _.get(activity, 'activity_type', '');
    const methods = DATA_UPDATE_METHODS.join('|');
    const updateRegex = new RegExp(`^DataUpdate\.(${methods})\.(SuccessWithDataErrors|Failure)`);
    const scheduleRegex = /schedulefailed(fatally)?/gi;
    const isAgentOffline = activityType === 'AgentOffline';
    return updateRegex.test(activityType) || scheduleRegex.test(activityType) || isAgentOffline;
  }

  /* Finds the most recent instance of AssetDeleted activity in
   * the list for a particular view by view_uid or dataset_uid,
   * and checks whether that asset is restorable. Only default
   * and derived tabular views are restorable */
  isRestorable() {
    const { restorableMap, activity, data } = this.props;
    var activitiesWithSameUid;
    var uid;

    if (activity.view_uid) { // if view_uid property exists, it's a derived view
      uid = activity.view_uid;
      activitiesWithSameUid = _.filter(data, {
        view_uid: uid,
        activity_type: 'AssetDeleted'
      });
    } else {
      uid = activity.dataset_uid;
       // Limit to only dataset asset types since filtered views are
       // restorable and have the same dataset_uid as default datasets.
      activitiesWithSameUid = _.filter(data, {
        dataset_uid: uid,
        activity_type: 'AssetDeleted',
        asset_type: 'dataset'
      });
    }

    const firstInList = _.get(activitiesWithSameUid, '[0].id');

    return firstInList === activity.id && !_.isUndefined(restorableMap[uid]) && restorableMap[uid].restorable;
  }

  isRestored() {
    const { restorableMap, activity } = this.props;
    const uid = activity.view_uid || activity.dataset_uid;
    // Check if restorableMap has uid as a key and the restored value is true.
    return !_.isUndefined(restorableMap[uid]) && restorableMap[uid].restored;
  }

  handleRestoreClicked = () => {
    const { activity } = this.props;
    this.props.showRestoreModal(activity.view_uid || activity.dataset_uid);
  }

  renderRestoreButton() {
    const linkProps = {
      href: '#',
      className: 'unstyled-link',
      tabIndex: 0,
      onClick: this.handleRestoreClicked
    };

    return (
      <a {...linkProps}>
        <LocalizedText localeKey="screens.admin.activity_feed.restore" />
      </a>
    );
  }

  renderRestoredIndicator() {
    return (
      <LocalizedText
        className="restored-dataset"
        localeKey="screens.admin.activity_feed.restored" />
    );
  }

  renderDetailsButton = () => {
    const { activity, openDetailsId, showDetails, hideDetails } = this.props;

    const linkProps = {
      href: '#',
      className: 'unstyled-link',
      tabIndex: 0
    };

    if (openDetailsId && openDetailsId === activity.id) {
      linkProps.onClick = hideDetails;

      return (
        <a {...linkProps}>
          <LocalizedText localeKey="screens.admin.activity_feed.hide_details" />
        </a>
      );
    } else {
      linkProps.onClick = showDetails.bind(null, activity.id);

      return (
        <a {...linkProps}>
          <LocalizedText localeKey="screens.admin.activity_feed.show_details" />
        </a>
      );
    }
  }

  render() {
    const { activity } = this.props;

    const hasDetails = this.hasDetails(activity);
    const isRestorable = this.isRestorable(activity);
    const isRestored = this.isRestored(activity);

    if (isRestorable) {
      return this.renderRestoreButton();
    } else if (isRestored) {
      return this.renderRestoredIndicator();
    } else if (hasDetails) {
      return this.renderDetailsButton();
    } else {
      return null;
    }
  }
}

ActionsCell.defaultProps = {
  openDetailsId: null,
  restorableMap: {}
};

ActionsCell.propTypes = {
  activity: propTypes.object.isRequired,
  showDetails: propTypes.func.isRequired,
  hideDetails: propTypes.func.isRequired,
  openDetailsId: propTypes.string,
  showRestoreModal: propTypes.func.isRequired,
  restorableMap: propTypes.object
};

const mapStateToProps = (state) => ({
  restorableMap: state.table.restorableMap,
  data: state.table.data
});

const mapDispatchToProps = dispatch => ({
  showDetails: (id) => dispatch(actions.table.showDetails(id)),
  hideDetails: () => dispatch(actions.table.hideDetails()),
  showRestoreModal: (id) => dispatch(actions.common.showRestoreModal(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsCell);
