/* eslint dot-location: 0 */

import _ from 'lodash';
import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import I18nJS from 'common/i18n';
import LocalizedDate from 'common/i18n/components/LocalizedDate';
import LocalizedText from 'common/i18n/components/LocalizedText';
// careful using this LocalizedLink - it doesn't actually work for every page.
// many react-router driven pages don't actually accept a locale as the first
// component of the link.
import LocalizedLink from 'common/i18n/components/LocalizedLink';
import AssetTypeIcon from 'common/components/AssetTypeIcon';
import ActionsCell from './ActionsCell';
import DetailsRow from './DetailsRow';
import { ASSET_TYPES } from '../../model';

class Body extends Component {

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.data, nextProps.data) ||
      this.props.openDetailsId !== nextProps.openDetailsId;
  }

  renderTypeCell(data) {
    let tooltip = I18nJS.lookup(`screens.admin.activity_feed.asset_types.${data.asset_type}`);
    let displayType = data.asset_type;

    if (!tooltip) {
      tooltip = I18nJS.lookup('screens.admin.activity_feed.asset_types.dataset');
      displayType = 'dataset';
    }

    return (
      <td className="type">
        <AssetTypeIcon {...{ tooltip, displayType }} />
      </td>
    );
  }

  renderInitiatedByCell(data) {
    let profileLink;

    if (!_.isUndefined(data.acting_user_id)) {
      const profilePath = `/profile/${data.acting_user_id}`;

      profileLink = (
        <LocalizedLink className="unstyled-link" path={profilePath}>
          {data.acting_user_name}
        </LocalizedLink>
      );
    }

    return (
      <td className="initiated-by">
        {profileLink}
      </td>
    );
  }

  renderEventCell(data) {
    const localeKey = `screens.admin.activity_feed.filters.events.options.${_.snakeCase(data.activity_type)}`;

    return (
      <td className="event">
        <LocalizedText localeKey={localeKey} />
      </td>
    );
  }

  /**
   * Given an activity, this will look at the dataset/story/user/etc. and determine
   * what path should be used in the "Affected Item" cell link.
   *
   * If no path can be determined, returns null.
   */
  getAffectedItemLinkPath = (data) => {
    const datasetTypes = _.chain(ASSET_TYPES)
                          .omit([ASSET_TYPES.FILTERED_VIEW, ASSET_TYPES.STORY, ASSET_TYPES.FORM])
                          .values()
                          .concat('visualization', 'href', 'federated_href')
                          .value();

    // The presence of a view_uid means this is a derived view, so link to that rather
    // than the parent, whose 4x4 is the dataset_uid
    const isSupportedAssetWithViewId = (d) => datasetTypes.includes(d.asset_type) && d.view_uid;
    const isSupportedAssetWithDatasetId = (d) => datasetTypes.includes(d.asset_type) && d.dataset_uid;
    const isUser = (d) => d.asset_type === 'user' && d.target_user_id_1;
    const isStory = (d) => d.asset_type === 'story' && d.dataset_uid;
    const isAgent = (d) => d.asset_type === 'agent' && d.activity_type.toLowerCase() !== 'agentdeleted'; // can't link to a deleted thing

    return _.cond([
      [isSupportedAssetWithViewId, (d) => `/dataset/${d.view_uid}`],
      [isSupportedAssetWithDatasetId, (d) => `/dataset/${d.dataset_uid}`],
      [isUser, (d) => `/profile/${d.target_user_id_1}`],
      [isStory, (d) => `/stories/s/${d.dataset_uid}`],
      [isAgent, (d) => `/admin/gateway/${JSON.parse(d.details).agent_uid}`],
      [_.stubTrue, _.constant(null)]
    ])(data);
  }

  /** Used when the affected item doesn't have a link */
  getNonLinkAffectedItemText = (data) => {
    switch (data.affected_item) {
      case 'site':
        return I18nJS.lookup('screens.admin.activity_feed.asset_types.site');
      default:
        // fallback to non-localized "affected item"
        return data.affected_item;
    }
  }

  /**
   * Get the contents for an affected item cell; usually a link or some text
   */
  getAffectedItemCellContents = (data) => {
    const maybeLink = this.getAffectedItemLinkPath(data);

    return maybeLink ?
      <a className="unstyled-link" href={maybeLink} target="_blank">{data.affected_item}</a>
      : this.getNonLinkAffectedItemText(data);
  }

  renderItemAffectedCell = (data) => {
    return (
      <td className="item-affected">
        {this.getAffectedItemCellContents(data)}
      </td>
    );
  }

  renderDateCell(data) {
    return (
      <td className="date">
        <LocalizedDate date={data.created_at} withTime includeSeconds />
      </td>
    );
  }

  renderActionsCell(data, openDetailsId) {
    return (
      <td className="actions">
        <ActionsCell activity={data} openDetailsId={openDetailsId} />
      </td>
    );
  }

  renderRow = (row) => {
    const { openDetailsId } = this.props;

    const rows = [(
      <tr key={row.id} className="result-list-row">
        {this.renderTypeCell(row)}
        {this.renderInitiatedByCell(row)}
        {this.renderEventCell(row)}
        {this.renderItemAffectedCell(row)}
        {this.renderDateCell(row)}
        {this.renderActionsCell(row, openDetailsId)}
      </tr>
    )];

    if (openDetailsId && openDetailsId === row.id) {
      rows.push(<DetailsRow activity={row} />);
    }

    return rows;
  }

  render() {
    const { data } = this.props;

    return (
      <tbody>
        {data.map(this.renderRow)}
      </tbody>
    );
  }
}

Body.defaultProps = {
  data: []
};

Body.propTypes = {
  data: propTypes.array.isRequired,
  openDetailsId: propTypes.string
};

const mapStateToProps = state => ({
  data: state.table.data,
  openDetailsId: state.table.openDetailsId
});

export default connect(mapStateToProps)(Body);
